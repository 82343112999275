import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-contact",
  "title": "Kontakta oss",
  "bg": "/img/bg-about.jpg",
  "email-form": {
    "name-field": "Namn",
    "email-field": "Epost",
    "phone-field": "Nummer",
    "button-text": "Skicka"
  },
  "eee": "eee",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "emailForm": {
    "nameField": "Namn",
    "emailField": "Epost",
    "phoneField": "Telefon",
    "buttonText": "Skicka",
    "messageField": "Meddelande"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      